/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.0/dist/jquery.min.js
 * - /npm/popper.js@1.15.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.0/dist/js/bootstrap.min.js
 * - /npm/scrollpos-styler@0.7.1/scrollPosStyler.min.js
 * - /npm/jquery-slimscroll@1.3.8/jquery.slimscroll.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
